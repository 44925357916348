import React from "react";

function Support() {
    return (
        <>
            <header>
                <h1>Support me</h1>

                <p>If you would like to support me please consider using one of my refferal links</p>
            </header>

            <main>
                <ul>
                    <li><a href="https://m.do.co/c/dea049b7c215">Digital Ocean</a></li>
                </ul>
            </main>
        </>
    );
};

export default Support;
